// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/network.tsx"
);
import.meta.hot.lastModified = "1727886656296.2722";
}
// REMIX HMR END

import {
  json,
  type ActionArgs,
  type ActionFunction,
  type AppData,
  type LoaderArgs,
  type LoaderFunction,
  type TypedResponse,
} from '@remix-run/node'
import {
  useFetchers,
  useParams,
  type Params,
  type useActionData,
} from '@remix-run/react'
import { type QueryKey } from '@tanstack/react-query'
import { useEffect, useMemo, useRef } from 'react'

import { truthyFn } from '@/constants'
import { type HashTable, type Nullish, type ObjectFromKeys } from '@/types'
import {
  invariant,
  unknownToErrorContent,
  unknownToErrorContentAsync,
} from '@/utils'

import { getActiveToasts, Toast, toast } from '~/ui/atoms/Toast'

import { parseSearchParams } from '~/ui/molecules/DataTable/utils'

import packageJson from '~/../package.json'
import { type KnowledgeOriginFilter, type KnowledgeStateFilter } from '~/api'
import { DEFAULT_PAGE_SIZE } from '~/config'
import { logger } from '~/logging/log.client'
import { log } from '~/logging/log.server'
import { type RouteResponse } from '~/utils'

import { throwResponse } from './throwResponse.server'

const appVersion = packageJson.version

export type ActionData<T = unknown> = ReturnType<typeof useActionData<T>>
export type RouteActionData<T = unknown> = ActionData<RouteResponse<T>>

export async function getThrowableErrorContentResponseAsync(
  e: unknown,
  message: string,
) {
  const errorContent = await unknownToErrorContentAsync(e)
  log.error('Error occurred while %s: %o', message, errorContent)
  log.error('originalError: %o', e)
  return json(errorContent, { status: errorContent.status_code })
}

export function parseUrl(propUrl: string | URL) {
  const url = propUrl instanceof URL ? propUrl : new URL(propUrl)
  return Object.assign(url, { fullUrl: url.toString() })
}

export function parseWindowUrl() {
  return parseUrl(window.location.href)
}

// Remove array support -- it was added for convenience, but the logic is incorrect
// and adds additional complexity
export function getQueryParams<
  QueryParams extends HashTable<string | string[]> = HashTable<string>,
>(
  urlOrRequest: string | URL | URLSearchParams | Request,
  requiredParams: string[] = [],
) {
  const searchParams =
    urlOrRequest instanceof URLSearchParams
      ? urlOrRequest
      : urlOrRequest instanceof Request
      ? new URL(urlOrRequest.url).searchParams
      : typeof urlOrRequest === 'string'
      ? new URL(urlOrRequest).searchParams
      : urlOrRequest.searchParams

  const queryParams: HashTable<string | string[]> = {}

  // Array.from(searchParams.entries()).forEach(([key, value]) => {
  searchParams.forEach((value, key) => {
    if (queryParams[key]) {
      // The key has already been added, so there are multiples.
      const currParam = queryParams[key]
      queryParams[key] = Array.isArray(currParam) ? currParam : [currParam]
      // Add the new value to the array
      ;(queryParams[key] as string[]).push(value)
    } else {
      // Otherwise, simply add the key-value pair to the object
      queryParams[key] = value
    }
  })

  requiredParams.forEach((param) => {
    const currParam = queryParams[param]
    if (currParam == null) {
      const errorMessage = `Missing required param: ${param}`
      throwResponse(errorMessage, 404)
    }
  })

  return queryParams as QueryParams
}

/**
 * Utility to get the path and query key to an action for a resource route.
 *
 * @example
 * ```typescript
 * const requiredParams = ['accountName', 'conversationId', 'cellId']
 *
 * const path = usePath('cell/update', requiredParams, { cellId: '123' })
 *
 * const action = ({ request, context }: ActionArgs) => {
 *   const { accountName, conversationId, cellId } = requireSearchParams(
 *     new URL(request.url).searchParams,
 *     requiredParams,
 *   )
 * }
 * ```
 */
export function useResourcePath<RequiredParam extends string>(
  route: string,
  requiredParams: ReadonlyArray<RequiredParam> = [],
  params: { [Key in RequiredParam]?: string | null } = {},
  urlSearchParams?: URLSearchParams,
): { key: QueryKey; path: string; hasAllParams: boolean } {
  const scopedParams = useParams()
  return getResourcePathData(
    route,
    requiredParams,
    params,
    scopedParams,
    urlSearchParams,
  )
}

export function getResourcePathData<RequiredParam extends string>(
  route: string,
  requiredParams: ReadonlyArray<RequiredParam> = [],
  params: { [Key in RequiredParam]?: string | null } = {},
  /** scopedParams is the result of `useParams` hook */
  scopedParams: Readonly<Params<string>> = {},
  urlSearchParams?: URLSearchParams,
): { key: QueryKey; path: string; hasAllParams: boolean } {
  const parsedParams = parseParams(
    { ...scopedParams, ...params },
    requiredParams,
  )
  let searchParams = Array.from(
    Object.entries({ ...params, ...parsedParams } as { [s: string]: string }),
  )
  // urlSearchParams of type URLSearchParams to support duplicate keys e.g. tags=abc&tags=test
  if (urlSearchParams) {
    searchParams = searchParams.concat(Array.from(urlSearchParams.entries()))
  }
  const combinedParams = new URLSearchParams(searchParams)
  const [type, action] = route.split('/')
  const path = `/resources/${type}/${action}?${combinedParams.toString()}`
  const key = ['resources', type, parsedParams, action]
  const hasAllParams = requiredParams.every(
    (p) =>
      p in parsedParams && parsedParams[p] != null && parsedParams[p] !== '',
  )
  return { key, path, hasAllParams }
}

// TODO I was running into some type compilation errors when using SerializeFrom
// directly, so I just redefined it here without the Serialize type.
type FunctionT = LoaderFunction | ActionFunction
type ArgsT = LoaderArgs | ActionArgs
export type SerializeFrom<T extends AppData | FunctionT> = T extends (
  args: ArgsT,
) => infer Output
  ? Awaited<Output> extends TypedResponse<infer U>
    ? U
    : Awaited<Output>
  : Awaited<T>

// Opinionated request function that sends and receives JSON by default.
type Init = Omit<RequestInit, 'body' | 'method'>
async function request<T extends FunctionT>(
  method: string,
  path: string,
  data?: unknown,
  init?: Init,
) {
  // If no headers were specified, default to JSON in and JSON out.
  const headers = new Headers(init?.headers)
  let body = data as BodyInit
  if (init?.headers == null) {
    headers.set('Content-Type', 'application/json')
    body = JSON.stringify(data)
  }

  const res = await fetch(path, { ...init, headers, method, body })
  const resBody = await res.json()
  return resBody as SerializeFrom<T>
}

export const http = {
  get: <T extends FunctionT>(path: string, init?: Init) =>
    request<T>('GET', path, undefined, init),
  post: <T extends FunctionT>(path: string, data?: unknown, init?: Init) =>
    request<T>('POST', path, data, init),
  put: <T extends FunctionT>(path: string, data?: unknown, init?: Init) =>
    request<T>('PUT', path, data, init),
  patch: <T extends FunctionT>(path: string, data?: unknown, init?: Init) =>
    request<T>('PATCH', path, data, init),
  delete: <T extends FunctionT>(path: string, init?: Init) =>
    request<T>('DELETE', path, undefined, init),
}

/**
 * @param urlOrPathname either the pathname as a string, or a URL object
 */
export function isResourceUrl(urlOrPathname: URL | string) {
  const pathname =
    typeof urlOrPathname === 'string' ? urlOrPathname : urlOrPathname.pathname
  return pathname.startsWith('/resources') || pathname.includes('/api/')
}

type ResponseType = unknown | FunctionT
// TODO: convert this to a class which extends OpenAPI like in `api.server.ts`, but
// make it for browser-side use, and add token handling on the client side, then we
// can directly call MLCore APIs from the browser without writing custom loader/action functions
// TODO: rename to `request` once we have converted the older request code to this
async function requestWithThrow<T extends ResponseType>(
  method: string,
  path: string,
  data?: unknown,
  init?: Init,
) {
  try {
    // If no headers were specified, default to JSON in and JSON out.
    const headers = new Headers(init?.headers)
    let body = data as BodyInit
    if (init?.headers == null) {
      headers.set('Content-Type', 'application/json')
      body = JSON.stringify(data)
    }

    const res = await fetch(path, {
      ...init,
      headers,
      method,
      body,
      credentials: 'include',
    })

    // Only show the version changed toast if the header exists
    const versionHeader = res.headers.get('NS-App-Version')
    if (versionHeader != null && versionHeader !== appVersion) {
      const toastTitle = 'New version available'
      // only show version mismatch toast once
      if (!getActiveToasts().some((t) => t.title === toastTitle)) {
        toast({
          variant: 'warning',
          title: toastTitle,
          dismissible: false,
          action: (
            <Toast.Action
              altText='refresh page'
              onClick={() => window.location.reload()}
            >
              Refresh
            </Toast.Action>
          ),
        })
      }
    }

    if (!res.ok) {
      if (res.status === 401 && typeof window !== 'undefined') {
        // Redirect to login if unauthenticated
        window.location.href = '/login?expired=true'
      }
      // res.json() breaks when data is not json, so wrap in a try/catch.
      // Also throw the error if it exists, and the catch will be hit either way
      throw await res.json()
    }

    if (res.redirected) {
      // Make sure we're not redirecting to an API endpoint
      if (!isResourceUrl(new URL(res.url))) window.location.href = res.url
    }
    // // Process your response as usual
    const resBody = await res.json()
    return resBody as SerializeFrom<T>
  } catch (e) {
    const errorContent = unknownToErrorContent(e)
    // TODO: add log service-agnostic logging object to project
    // and then use it here and other places in the app for better visibility into errors.
    // it should be able to log to Sentry/New Relic/Customer's choice, or a combination of all
    if (errorContent.status_code === 403) {
      // TODO: delete client-side token and log user out
      // Adding this for awareness as we move toward SPA
      logger.console('error', 'got a 403 in browser http client')
    }
    throw errorContent
  }
}

// TODO: rename to `http` once we have converted the older http code to this
export const httpWithThrow = {
  get: <T extends ResponseType>(path: string, init?: Init) =>
    requestWithThrow<T>('GET', path, undefined, init),
  post: <T extends ResponseType>(path: string, data?: unknown, init?: Init) =>
    requestWithThrow<T>('POST', path, data, init),
  put: <T extends ResponseType>(path: string, data?: unknown, init?: Init) =>
    requestWithThrow<T>('PUT', path, data, init),
  patch: <T extends ResponseType>(path: string, data?: unknown, init?: Init) =>
    requestWithThrow<T>('PATCH', path, data, init),
  delete: <T extends ResponseType>(path: string, init?: Init) =>
    requestWithThrow<T>('DELETE', path, undefined, init),
}

function requireSearchParamsFromRequest<
  ParamKeys extends string,
  RequiredParam extends ParamKeys,
>(req: Request, requiredParams: ReadonlyArray<RequiredParam>) {
  const { searchParams } = new URL(req.url)
  return requireSearchParamsFromURLSearchParams(searchParams, requiredParams)
}

function requireSearchParamsFromURLSearchParams<
  ParamKeys extends string,
  RequiredParam extends ParamKeys,
>(searchParams: URLSearchParams, requiredParams: ReadonlyArray<RequiredParam>) {
  const parsedParamerters = requiredParams.reduce((acc, curr) => {
    const currParam = searchParams.get(curr)
    if (currParam == null) {
      const errorMessage = `Missing required param: ${curr}`
      throwResponse(errorMessage, 404)
    } else {
      acc[curr] = currParam
    }
    return acc
  }, {} as ObjectFromKeys<RequiredParam, string>)

  return parsedParamerters
}

export function requireSearchParams<
  ParamKeys extends string,
  RequiredParam extends ParamKeys,
>(
  obj: Request | URLSearchParams,
  requiredParams: ReadonlyArray<RequiredParam>,
): ObjectFromKeys<RequiredParam, string> {
  if (obj instanceof Request)
    return requireSearchParamsFromRequest(obj, requiredParams)
  return requireSearchParamsFromURLSearchParams(obj, requiredParams)
}

function parseParams<ParamKeys extends string, ParamKey extends ParamKeys>(
  params: Params<ParamKeys>,
  paramKeys: ReadonlyArray<ParamKey>,
): { [Key in ParamKey]: Nullish<string> } {
  return paramKeys.reduce((acc, curr) => {
    const currParam = params[curr]
    acc[curr] = currParam
    return acc
  }, {} as { [Key in ParamKey]: Nullish<string> })
}

export function requireParams<
  ParamKeys extends string,
  RequiredParam extends ParamKeys,
>(
  params: Params<ParamKeys>,
  requiredParams: ReadonlyArray<RequiredParam>,
): { [Key in RequiredParam]: string } {
  const parsedParams = parseParams(params, requiredParams)
  invariant(
    requiredParams.every((param) => parsedParams[param] != null),
    'Missing required param',
  )
  return parsedParams as { [Key in RequiredParam]: string }
}

export const SEARCH_PARAM = 'search'
export const KNOWLEDGE_STATE_KEY = 'state'
export const KNOWLEDGE_ORIGIN_KEY = 'origin'

export type Pagination = {
  limit?: number
  skip?: number
  descending?: boolean
  sortBy?: string
  filterBy?: string
  search?: string
  state?: KnowledgeStateFilter
  origin?: KnowledgeOriginFilter
}

/**
 * Get the pagination parameters from the URL query of the request URL. This is
 * included here primarily to ensure that we use consistent parameter names.
 *
 * This should be used in conjunction with `getPaginationQuery` client-side.
 */
export function getPaginationParams(url: string): Pagination {
  return paramsToPagination(new URL(url).searchParams)
}

export function paginationToParams(pagination: Pagination): URLSearchParams {
  const query: [string, string][] = []
  if (pagination.limit != null)
    query.push(['limit', pagination.limit.toString()])
  if (pagination.skip != null) query.push(['skip', pagination.skip.toString()])
  if (pagination.descending != null)
    query.push(['descending', pagination.descending.toString()])
  if (pagination.sortBy != null)
    query.push(['sortBy', pagination.sortBy.toString()])
  if (pagination.filterBy != null)
    query.push(['filterBy', pagination.filterBy.toString()])
  if (pagination.search != null)
    query.push([SEARCH_PARAM, pagination.search.toString()])
  // TODO move state and origin to a different param function, they are not related to pagination
  if (pagination.state != null)
    query.push([KNOWLEDGE_STATE_KEY, pagination.state.toString()])
  if (pagination.origin != null)
    query.push([KNOWLEDGE_ORIGIN_KEY, pagination.origin.toString()])
  return new URLSearchParams(query)
}

export function paramsToPagination(searchParams: URLSearchParams): Pagination {
  const limit = Number(searchParams.get('limit') ?? undefined)
  const skip = Number(searchParams.get('skip') ?? undefined)
  return {
    limit: Number.isNaN(limit) ? undefined : limit,
    skip: Number.isNaN(skip) ? undefined : skip,
    descending: searchParams.get('descending') === 'true',
    sortBy: searchParams.get('sortBy') ?? undefined,
    filterBy: searchParams.get('filterBy') ?? undefined,
    search: searchParams.get(SEARCH_PARAM) ?? undefined,
  }
}

/**
 * Encode the pagination parameters into a URL query. This is included here
 * primarily to ensure that we use consistent parameter names.
 *
 * This should be used in conjunction with `getPaginationParams` server-side.
 */
export function getPaginationQuery(pagination: Pagination): string {
  return paginationToParams(pagination).toString()
}

/**
 * A hook to be used when you want to know if any fetcher in the route
 * is in a non-idle state.
 *
 * @returns true if any fetchers are in a non-idle state
 */
export function useRequestInProgress(
  filterFn: (
    fetcher: ReturnType<typeof useFetchers>[number],
  ) => boolean = truthyFn,
) {
  const fetchers = useFetchers()

  const filterFnRef = useRef(filterFn)
  useEffect(() => {
    filterFnRef.current = filterFn
  })

  const inProgress = useMemo(
    () => fetchers.some((f) => f.state !== 'idle' && filterFnRef.current(f)),
    [fetchers],
  )

  return inProgress
}

export function getTableParamsFromUrl(url: string, id: string) {
  const urlObj = new URL(url)
  const { pagination, filters, sorting } = parseSearchParams(
    urlObj.searchParams,
  )
  const body = {
    filters: filters[id],
    sorts: sorting[id]?.map((s) => ({
      column: s.id,
      ascending: !s.desc,
    })),
  }
  const pageIndex = pagination[id]?.pageIndex ?? 0
  const pageSize = pagination[id]?.pageSize ?? DEFAULT_PAGE_SIZE

  return {
    body,
    pageIndex,
    pageSize,
  }
}
